import { Checkbox } from 'components/Atoms/Checkbox'
import { Select } from 'components/Atoms/Select'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { path } from 'constants/path'
import { useAppSelector } from 'app/hooks'
import { Card } from 'components/Atoms/Card'
import { Button } from 'components/Atoms/Button'
import { HoverIcon } from 'components/Atoms/HoverIcon'
import { LabelTypography } from 'components/Atoms/LabelTypography'
import { Pagination } from 'components/Atoms/Pagination'
import TableSkeleton from 'components/Atoms/Skeleton/TableSkeleton'
import { Table } from 'components/Atoms/Table'
import { Typography } from 'components/Atoms/Typography'
import { queryKeys } from 'constants/queryKeys'
import {
  addMemberToTraining,
  addTrainigFile,
  getTrainingDetails,
  getTrainingMembersList,
  removeMemberFromTraining,
  updateTraining,
} from 'fetchers/trainingFetchers'
import { useLastPage } from 'hooks/useLastPage'
import useSearch from 'hooks/useSearch'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { IOption } from 'types/form'
import { Calendar } from 'components/Atoms/Calendar'
import { TextField } from 'components/Atoms/TextField'
import { errorToast, successToast } from 'tools/ToastHelpers'
import { AxiosError } from 'axios'
import { IAxiosErrors } from 'types/axios-errors'
import { toast } from 'react-toastify'
import { mutationErrorHandler } from 'tools/errorHandler'
import { exportTrainingErrors } from 'error-data/training'
import { getMembersList } from 'fetchers/membersFetchers'
import { Modal } from 'components/Atoms/Modal'
import { MultiSelect } from 'react-multi-select-component'

const searchParams = {
  simpleParams: [],
  arrayParams: [],
}

const TrainingUpdate = () => {
  const [trainingName, setTrainingName] = useState('')
  const [trainerName, setTrainerName] = useState('')
  const [trainingHour, setTrainingHour] = useState('')
  const [trainigType, setTrainingType] = useState('')
  const [trainingDateStart, setTrainingDateStart] = useState('')
  const [trainingLocation, setTrainingLocation] = useState('')
  const [trainingUrl, setTrainingUrl] = useState('')
  const [locationDetails, setLocationDetails] = useState('')
  const [trainingDescription, setTrainingDescription] = useState('')
  const [isSendingTraining, setIsSendingTraining] = useState(false)
  const [isSendingMember, setIsSendingMember] = useState(false)
  const [addNewMember, setAddNewMember] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const [fileIsAdding, setFileIsAdding] = useState(false)
  const [file, setFile] = useState<File>()
  const [fileIds, setFileIds] = useState<string[]>([])
  const [finalFiles, setFinalFiles] = useState<IOption[]>([])
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [isSending, setIsSending] = useState(false)

  const { id } = useParams()
  const { control } = useForm()
  const navigate = useNavigate()
  const cardId = id as string
  const { userToken } = useAppSelector((state: any) => state.authReducer)

  const { filters, perPage, page, setLimit, changePage } = useSearch(searchParams)

  const {
    data: trainingDetails,
    isLoading: isTrainingDetailsLoading,
    error: trainingDetailsError,
    refetch: refetchTrainingDetails,
  } = useQuery({
    queryKey: [userToken, queryKeys.trainingDetails, cardId],
    queryFn: () => getTrainingDetails(cardId),
  })

  useEffect(() => {
    if (trainingDetails) {
      setTrainingName(trainingDetails?.name || '')
      setTrainerName(trainingDetails?.trainerName || '')
      setTrainingHour(trainingDetails?.hourStart || '')
      setTrainingType(trainingDetails?.type || '')
      setTrainingDateStart(trainingDetails?.dateStart || '')
      setTrainingLocation(trainingDetails?.location || '')
      setTrainingUrl(trainingDetails?.url || '')
      setLocationDetails(trainingDetails?.locationDetails || '')
      setTrainingDescription(trainingDetails?.description || '')
      setFileIds(
        trainingDetails?.files.map((file: any) => (typeof file === 'string' ? file : file.id)) ||
          [],
      )
      setFinalFiles(
        trainingDetails?.files.map((file: any, key: any) => ({
          value: typeof file === 'string' ? file : file.id,
          label: `Plik ${key + 1}`,
        })) || [],
      )
    }
  }, [trainingDetails])

  const {
    data: membersList,
    isLoading: isAllMembersListLoading,
    error: allMembersListError,
    refetch: refetchAllMembersList,
  } = useQuery({
    queryKey: [userToken, queryKeys.membersList],
    queryFn: () => getMembersList(1, 100),
  })

  const allMembersList = membersList?.items || []

  const {
    data: trainingMembersList,
    isLoading: isTrainingMembersListLoading,
    error: trainingMembersListError,
    refetch: refetchTrainingMembersList,
  } = useQuery({
    queryKey: [userToken, queryKeys.trainingMembersList, cardId],
    queryFn: () => getTrainingMembersList(cardId),
  })

  const lastPage = useLastPage(trainingMembersList?.pagination, isTrainingMembersListLoading)
  const generatedExportsList = trainingMembersList?.items || []
  const onLimitHandler = (e: IOption) => setLimit(e.value)

  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: (data: any) => {
      const { redacted, status, ...formData } = data
      return updateTraining(cardId, formData)
    },
    onSuccess: () => {
      successToast('Zapisano zmiany dotyczące szkolenia.')
      queryClient.invalidateQueries({ queryKey: [queryKeys.trainingCreate] })
      navigate(path.training.list)
    },
    onError: (error: AxiosError<IAxiosErrors>) => {
      setIsSendingTraining(false)
      error.response?.data.errors.forEach((item: any) => {
        toast.warning(`${item.property}: ${item.error}`)
      })
      mutationErrorHandler(error, exportTrainingErrors.create, 'Nie udało się dodać szkolenia.')
    },
  })

  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!trainingName.trim()) {
      errorToast('Nazwa pliku jest wymagana.')
      return
    }
    if (!trainerName.trim()) {
      errorToast('Imię i nazwisko prowadzącego jest wymagane.')
      return
    }
    if (!trainingDateStart.trim()) {
      errorToast('Data rozpoczęcia jest wymagana.')
      return
    }
    if (!trainingHour.trim()) {
      errorToast('Godzina rozpoczęcia jest wymagana.')
      return
    }
    if (!trainigType.trim()) {
      errorToast('Tryb szkolenia jest wymagany.')
      return
    }
    if (!trainingLocation.trim()) {
      errorToast('Miejsce szkolenia jest wymagane.')
      return
    }
    if (!trainingUrl.trim()) {
      errorToast('URL jest wymagane.')
      return
    }
    if (!locationDetails.trim()) {
      errorToast('Szczegóły lokalizacji są wymagane.')
      return
    }
    if (!trainingDescription.trim()) {
      errorToast('Opis szkolenia jest wymagany.')
      return
    }

    setIsSendingTraining(true)

    const exportData = {
      name: trainingName,
      dateStart: trainingDateStart,
      hourStart: trainingHour,
      trainerName: trainerName,
      location: trainingLocation,
      locationDetails: locationDetails,
      type: trainigType,
      url: trainingUrl,
      description: trainingDescription,
      // map thorugh fileIds and return only array of strings
      files: fileIds.map((fileId) => fileId),
    }

    mutation.mutate(exportData)
  }

  const memberMutation = useMutation({
    mutationFn: (memberId: string) => addMemberToTraining(cardId, memberId),
    onSuccess: () => {
      successToast('Dodano uczestnika do szkolenia.')
      queryClient.invalidateQueries({ queryKey: [queryKeys.trainingAddMemberToList] })
      refetchTrainingMembersList()
      setIsSendingMember(false)
    },
    onError: (error: AxiosError<IAxiosErrors>) => {
      setIsSendingMember(false)
      error.response?.data.errors.forEach((item: any) => {
        toast.warning(`${item.property}: ${item.error}`)
      })
      mutationErrorHandler(error, exportTrainingErrors.create, 'Nie udało się dodać uczestnika.')
    },
  })

  const memberSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const memberId = addNewMember
    setIsSendingMember(true)
    memberMutation.mutate(memberId)
    setAddNewMember('')
    setIsOpen(false)
  }

  const removeMemberMutation = useMutation({
    mutationFn: (memberId: string) => removeMemberFromTraining(cardId, memberId),
    onSuccess: () => {
      successToast('Usunięto uczestnika ze szkolenia.')
      queryClient.invalidateQueries({ queryKey: [queryKeys.trainingRemoveMember] })
      refetchTrainingMembersList()
      setIsSendingMember(false)
    },
    onError: (error: AxiosError<IAxiosErrors>) => {
      setIsSendingMember(false)
      error.response?.data.errors.forEach((item: any) => {
        toast.warning(`${item.property}: ${item.error}`)
      })
      mutationErrorHandler(error, exportTrainingErrors.create, 'Nie udało się usunąć uczestnika.')
    },
  })

  const removeMemberHandler = (memberId: string) => {
    setIsSendingMember(true)
    removeMemberMutation.mutate(memberId)
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Imię i nazwisko',
        accessor: 'name',
        Cell: ({ row }: any) => {
          return row.original?.firstName + ' ' + row.original?.lastName
        },
      },
      {
        Header: 'Numer legitymacji',
        accessor: 'number',
        Cell: ({ row }: any) => {
          return row.original?.card
        },
      },
      {
        Header: 'Akcje',
        accessor: 'action',
        Cell: ({ row }: any) => {
          return (
            <div className='flex'>
              <HoverIcon
                disabled={row.original?.archived}
                iconName='EyeIcon'
                title='Zobacz dane uczestnika'
                onClick={() => {
                  navigate(`/member/${row.original.id}`)
                }}
              />
              <HoverIcon
                iconName='TrashIcon'
                title='Usuń uczestnika'
                onClick={() => {
                  confirm('Czy na pewno chcesz usunąć uczestnika z listy?') &&
                    removeMemberHandler(row.original.id)
                }}
              />
            </div>
          )
        },
      },
    ],
    [],
  )

  const filteredFiles = fileIds.map((fileId, key) => ({
    value: fileId,
    label: `Plik ${key + 1}`,
  }))

  const fileMutation = useMutation({
    mutationFn: (data: File) => {
      return addTrainigFile(data)
    },
    onSuccess: (data) => {
      const fileId = data.id
      const newFile = { value: fileId, label: `Plik ${fileIds.length + 1}` }
      setFileIds((prevFileIds) => [...prevFileIds, fileId])
      setFinalFiles((prevFinalFiles) => [...prevFinalFiles, newFile])
      if (fileInputRef.current) {
        fileInputRef.current.value = ''
      }
      successToast('Plik został dodany.')
      setFileIsAdding(false)
      setFile(undefined)
    },
    onError: (error: AxiosError<IAxiosErrors>) => {
      mutationErrorHandler(error, exportTrainingErrors.create, 'Nie udało się dodać pliku.')
      setFileIsAdding(false)
    },
  })

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files ? event.target.files[0] : undefined
    setFile(selectedFile)
  }

  const handleAddFile = (e: React.FormEvent) => {
    e.preventDefault()
    if (!file) {
      errorToast('Plik jest wymagany.')
      return
    }
    setFileIsAdding(true)
    fileMutation.mutate(file)
  }

  if (
    isTrainingDetailsLoading ||
    isTrainingMembersListLoading ||
    isAllMembersListLoading ||
    isSendingTraining
  ) {
    return (
      <Card label='Szkolenie'>
        <dl className='grid grid-cols-1 text-base/6 sm:grid-cols-[min(50%,theme(spacing.80))_auto] sm:text-sm/6'>
          {[...Array(6)].map((_, index) => (
            <React.Fragment key={index}>
              <dt className='col-start-1 border-t border-zinc-950/5 pt-3 text-zinc-500 first:border-none sm:border-t sm:border-zinc-950/5 sm:py-3'>
                <div className='h-4 w-full max-w-[112px] animate-pulse rounded-md bg-slate-200'></div>
              </dt>
              <dd className='pb-3 pt-1 text-zinc-950 sm:border-t sm:border-zinc-950/5 sm:py-3 dark:text-white dark:sm:border-white/5 sm:[&:nth-child(2)]:border-none'>
                <div className='h-4 w-full max-w-[287px] animate-pulse rounded-md bg-slate-200'></div>
              </dd>
            </React.Fragment>
          ))}
        </dl>
      </Card>
    )
  }

  return (
    <>
      <form onSubmit={submitHandler}>
        <Card label={`Edycja ${trainingDetails?.name}`}>
          <div className='grid grid-cols-1 gap-4 pb-4 sm:grid-cols-2 md:grid-cols-4'>
            <TextField
              onChange={(e) => {
                setTrainingName(e.target.value)
              }}
              label='Nazwa szkolenia'
              placeholder='Wpisz nazwę szkolenia'
              name='trainingName'
              type='text'
              value={trainingName || (trainingDetails?.name ? trainingDetails?.name : '')}
            />
            <div className='flex gap-2'>
              <div className='flex-1'>
                <Controller
                  name='dateStart'
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Calendar
                      date={trainingDateStart ? new Date(trainingDateStart) : new Date()}
                      handleDate={(val) => {
                        onChange(val)
                        setTrainingDateStart(new Date(val).toISOString().split('T')[0])
                      }}
                      label='Data rozpoczęcia'
                    />
                  )}
                />
              </div>
            </div>
            <div>
              <label
                className='mb-1 block text-sm font-medium text-gray-700'
                htmlFor='training-hour'
              >
                Godzina szkolenia
              </label>
              <input
                type='time'
                name='training-hour'
                value={trainingHour}
                onChange={(e) => {
                  setTrainingHour(e.target.value)
                }}
                className='block w-full rounded-md border-0 py-1.5 text-sm leading-6 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600'
                id='training-hour'
              />
            </div>
            <div>
              <label
                className='mb-1 block text-sm font-medium text-gray-700'
                htmlFor='training-type'
              >
                Tryb szkolenia
              </label>
              <select
                id='training-type'
                className='block w-full rounded-md border-0 py-1.5 text-sm leading-6 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600'
                value={trainigType || (trainingDetails?.type ? trainingDetails?.type : '')}
                onChange={(e) => {
                  setTrainingType(e.target.value)
                }}
              >
                <option value='on-site'>Stacjonarne</option>
                <option value='online'>Online</option>
              </select>
            </div>
            <TextField
              onChange={(e) => {
                setTrainerName(e.target.value)
              }}
              label='Prowadzący'
              placeholder='Wpisz imię prowadzącego'
              name='trainerName'
              type='text'
              value={trainerName}
            />
            {/* // dodaj brakujace pola: miejsce szkolenia, szczegoly lokalizacji, url, opis */}

            <div>
              <label
                className='mb-1 block text-sm font-medium text-gray-700'
                htmlFor='training-location'
              >
                Miejsce szkolenia
              </label>
              <input
                id='training-location'
                type='text'
                name='address'
                value={trainingLocation}
                onChange={(e) => {
                  setTrainingLocation(e.target.value)
                }}
                className='block w-full rounded-md border-0 py-1.5 text-sm leading-6 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600'
              />
            </div>
            <div>
              <label className='mb-1 block text-sm font-medium text-gray-700' htmlFor='url'>
                URL
              </label>
              <input
                id='url'
                type='text'
                name='url'
                className='block w-full rounded-md border-0 py-1.5 text-sm leading-6 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600'
                value={trainingUrl}
                onChange={(e) => {
                  setTrainingUrl(e.target.value)
                }}
              />
            </div>
            <div className='md:col-span-2'>
              <label
                className='mb-1 block text-sm font-medium text-gray-700'
                htmlFor='locationDetails'
              >
                Szczegóły lokalizacji
              </label>
              <textarea
                id='locationDetails'
                name='locationDetails'
                value={locationDetails}
                onChange={(e) => {
                  setLocationDetails(e.target.value)
                }}
                aria-rowspan={4}
                className='block w-full rounded-md border-0 py-1.5 text-sm leading-6 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600'
                placeholder='Wpisz szczegóły dot. lokalizacji'
              ></textarea>
            </div>
            <div className='md:col-span-2'>
              <label
                className='mb-1 block text-sm font-medium text-gray-700'
                htmlFor='trainingDescription'
              >
                Opis szkolenia
              </label>
              <textarea
                id='trainingDescription'
                name='trainingDescription'
                value={trainingDescription}
                onChange={(e) => {
                  setTrainingDescription(e.target.value)
                }}
                aria-rowspan={4}
                className='block w-full rounded-md border-0 py-1.5 text-sm leading-6 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600'
                placeholder='Opisz szkolenie'
              ></textarea>
            </div>
            <div>
              <label className='mb-1 block text-sm font-medium text-gray-700' htmlFor='docs'>
                Dokumenty
              </label>
              <MultiSelect
                options={filteredFiles}
                value={finalFiles}
                onChange={setFinalFiles}
                hasSelectAll={false}
                className='text-sm'
                overrideStrings={{
                  selectSomeItems: 'Zaznacz dokumenty',
                  allItemsAreSelected: 'Wszystkie dokumenty są zaznaczone',
                  selectAll: 'Zaznacz wszystkie',
                  search: 'Wyszukaj',
                  noOptions: 'Brak dokumentów',
                }}
                labelledBy='Wyszukaj'
              />
            </div>
            <div className='md:col-span-3'></div>
            <div className='md:col-span-2'>
              <label className='mb-1 block text-sm font-medium text-gray-700'>Dodaj plik</label>
              <div className='flex items-center gap-2'>
                <input
                  type='file'
                  name='file'
                  id='file'
                  ref={fileInputRef}
                  className='mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                  onChange={handleFileChange}
                />
                <Button
                  className='mt-1 flex min-w-[130px] justify-center text-center disabled:cursor-not-allowed'
                  disabled={!file}
                  label={
                    fileIsAdding ? (
                      <>
                        Dodaję plik
                        <span className='loader ml-1'></span>
                      </>
                    ) : (
                      'Dodaj plik'
                    )
                  }
                  onClick={handleAddFile}
                />
              </div>
            </div>
          </div>
        </Card>
        <Card label='Lista uczestników'>
          <div className='flex items-center justify-between gap-4'>
            {lastPage && (
              <>
                <div className='flex flex-col justify-end gap-4 md:flex-row'>
                  <div className='flex items-center gap-4'>
                    <Typography weight='semibold' className='text-sm'>
                      Liczba wierszy na stronę
                    </Typography>
                    <Select
                      handleSelect={onLimitHandler}
                      options={[
                        { label: '10', value: 10 },
                        { label: '20 ', value: 20 },
                      ]}
                    />
                  </div>
                </div>

                <span className='flex flex-col items-center gap-2 md:flex-row md:gap-4'>
                  <Button
                    label='Dodaj uczestnika'
                    onClick={(e: any) => {
                      e.preventDefault(e)
                      setIsOpen(true)
                    }}
                  />
                  {lastPage > 1 && (
                    <Pagination
                      lastPage={lastPage}
                      currentPage={page}
                      handlePageChange={changePage}
                    />
                  )}
                </span>
              </>
            )}
          </div>
          {!isTrainingMembersListLoading && (
            <>
              <Table maxColumnWidth='300px' columns={columns} data={generatedExportsList || []} />
            </>
          )}
          {isTrainingMembersListLoading && <TableSkeleton />}
        </Card>

        <hr className='my-4' />
        <div className='my-4 flex justify-center'>
          <a
            className='base-button mr-4 inline-flex items-center rounded border border-transparent bg-indigo-100 px-3 py-2 text-center text-xs font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:border-[1px] disabled:border-slate-200 disabled:bg-slate-50 disabled:text-slate-500 disabled:shadow-none'
            href={path.training.list}
          >
            Anuluj
          </a>
          <Button
            label={
              isSendingTraining ? (
                <>
                  Zapisuję zmiany
                  <span className='loader ml-1'></span>
                </>
              ) : (
                'Zapisz zmiany'
              )
            }
          />
        </div>
      </form>

      <Modal isOpen={isOpen}>
        <Card label='Dodaj uczestnika'>
          <form onSubmit={memberSubmitHandler} className='mt-2'>
            <select
              id='memberSelect'
              className='block w-full rounded-md border-0 py-1.5 text-sm leading-6 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600'
              name='memberSelect'
              value={addNewMember}
              onChange={(e: any) => {
                setAddNewMember(e.target.value)
              }}
              required
            >
              <option value=''>Wybierz uczestnika</option>
              {allMembersList?.map((member: any) => (
                <option key={member.id} value={member.id}>
                  {member.firstName} {member.lastName}
                </option>
              ))}
            </select>
            <div className='mt-4 flex justify-center gap-4'>
              <Button
                variant='secondary'
                label={'Anuluj'}
                onClick={(e) => {
                  e.preventDefault()
                  setIsOpen(false)
                }}
              />
              <Button
                label='Dodaj uczestnika'
                disabled={
                  addNewMember === '' ||
                  isAllMembersListLoading ||
                  isTrainingMembersListLoading ||
                  isSendingMember
                }
                icon={
                  (isAllMembersListLoading || isTrainingMembersListLoading || isSendingMember) && (
                    <span className='loader'></span>
                  )
                }
              />
            </div>
          </form>
        </Card>
      </Modal>
    </>
  )
}

export default TrainingUpdate
