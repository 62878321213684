import { Card } from 'components/Atoms/Card'
import { TextField } from 'components/Atoms/TextField'
import { useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Calendar } from 'components/Atoms/Calendar'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { queryKeys } from 'constants/queryKeys'
import { getBranchesListPrefiltred } from 'fetchers/branchFetchers'
import { getRegionsList } from 'fetchers/regionFetchers'
import { errorQuery } from 'tools/queryHelpers'
import { useAppSelector } from 'app/hooks'
import { MultiSelect } from 'react-multi-select-component'
import { createCensus } from 'fetchers/censusFetchers'
import { errorToast, successToast } from 'tools/ToastHelpers'
import { useNavigate } from 'react-router-dom'
import { AxiosError } from 'axios'
import { Button } from 'components/Atoms/Button'
import { mutationErrorHandler } from 'tools/errorHandler'
import { path } from 'constants/path'
import { IAxiosErrors } from 'types/axios-errors'
import { censusCreate } from 'error-data/census'
import { IUnitSelectedUnits } from 'types/structure'

const selectedUnitsInit: IUnitSelectedUnits = {
  region: [],
  branch: [],
  section: [],
  institution: [],
}

const CensusCreate = () => {
  const [censusName, setCensusName] = useState('')
  const [censusDateStart, setCensusDateStart] = useState('')
  const [censusDateEnd, setCensusDateEnd] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const [selectedUnits, setSelectedUnits] = useState<IUnitSelectedUnits>(selectedUnitsInit)

  const { userToken, unitContext } = useAppSelector((state: any) => state.authReducer)
  const { control } = useForm()
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const isRegion = unitContext?.type === 'region'

  // Fetch all regions
  const { data: regions } = useQuery({
    queryKey: [userToken, queryKeys.regionList],
    queryFn: () => getRegionsList(1, 1000, []), // Removed filtering by search
    retry: errorQuery,
  })

  // Fetch all branches
  const { data: branches } = useQuery({
    queryKey: [userToken, queryKeys.branchesList],
    queryFn: () => getBranchesListPrefiltred(1, 1000, ''), // Removed filtering by selected regions
    retry: errorQuery,
  })

  useEffect(() => {
    if (isRegion) {
      setSelectedUnits({ ...selectedUnitsInit, region: [unitContext.id] })
    } else {
      setSelectedUnits(selectedUnitsInit)
    }
  }, [unitContext?.id, isRegion, userToken])

  const regionOptions = useMemo(() => {
    return regions?.items?.map((item: any) => ({ label: item.name, value: item.id }))
  }, [regions])

  const branchOptions = useMemo(() => {
    return branches?.items?.map((item: any) => ({ label: item.name, value: item.id }))
  }, [branches])

  const mutation = useMutation({
    mutationFn: (data: any) => createCensus(data),
    onSuccess: () => {
      successToast('Spis został utworzony')
      queryClient.invalidateQueries({ queryKey: [userToken, queryKeys.documentList] })
      navigate('/census')
      setSubmitting(false)
    },
    onError: (error: AxiosError<IAxiosErrors>) => {
      setSubmitting(false)
      mutationErrorHandler(error, censusCreate.create, 'Nie udało się utworzyć spisu')
    },
  })

  const normalizeDate = (date: any) => {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate())
  }

  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setSubmitting(true)
    if (!censusName) {
      errorToast('Wpisz nazwę spisu')
      setSubmitting(false)
      return
    }
    // censusDateStart Ta wartość powinna być większa bądź równa dzisiaj
    if (normalizeDate(new Date(censusDateStart)) < normalizeDate(new Date())) {
      errorToast('Data rozpoczęcia nie może być wcześniejsza niż dzisiaj')
      setSubmitting(false)
      return
    }

    if (!censusDateStart || !censusDateEnd) {
      errorToast('Wybierz daty rozpoczęcia i zakończenia')
      setSubmitting(false)
      return
    }
    if (normalizeDate(new Date(censusDateStart)) >= normalizeDate(new Date(censusDateEnd))) {
      errorToast('Data rozpoczęcia nie może być późniejsza niż data zakończenia')
      setSubmitting(false)
      return
    }
    mutation.mutate({
      name: censusName,
      selectedRegions: selectedUnits.region,
      selectedBranches: selectedUnits.branch,
      dateFrom: censusDateStart,
      dateTo: censusDateEnd,
    })
  }

  return (
    <>
      <Card label='Utwórz spis'>
        <form onSubmit={submitHandler}>
          <div className='grid grid-cols-1 gap-4 pb-4 sm:grid-cols-2 md:grid-cols-4'>
            <TextField
              onChange={(e) => setCensusName(e.target.value)}
              label='Nazwa spisu'
              placeholder='Wpisz nazwę spisu'
              name='censusName'
              type='text'
              value={censusName}
            />
            <div>
              <p className='mb-1 block text-sm font-medium text-gray-700'>Wybierz okręg</p>
              <MultiSelect
                options={regionOptions || []}
                value={selectedUnits.region.map((region) => ({
                  label: regionOptions.find((option: any) => option.value === region)?.label,
                  value: region,
                }))}
                className='text-sm'
                onChange={(selected: any) =>
                  setSelectedUnits((prevUnits) => ({
                    ...prevUnits,
                    region: selected.map((region: any) => region.value),
                  }))
                }
                labelledBy='Select'
                overrideStrings={{
                  selectSomeItems: 'Wyszukaj',
                  search: 'Wyszukaj',
                  selectAll: 'Zaznacz wszystkie',
                  allItemsAreSelected: 'Wszystkie są zaznaczone',
                }}
              />
            </div>
            <div>
              <p className='mb-1 block text-sm font-medium text-gray-700'>Wybierz oddział</p>
              <MultiSelect
                options={branchOptions || []}
                value={selectedUnits.branch.map((branch) => ({
                  label: branchOptions.find((option: any) => option.value === branch)?.label,
                  value: branch,
                }))}
                className='text-sm'
                onChange={(selected: any) =>
                  setSelectedUnits((prevUnits) => ({
                    ...prevUnits,
                    branch: selected.map((branch: any) => branch.value),
                  }))
                }
                labelledBy='Select'
                overrideStrings={{
                  selectSomeItems: 'Wyszukaj',
                  search: 'Wyszukaj',
                  selectAll: 'Zaznacz wszystkie',
                  allItemsAreSelected: 'Wszystkie są zaznaczone',
                }}
              />
            </div>
            <div></div>
            <div>
              <Controller
                name='dateStart'
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Calendar
                    date={value}
                    handleDate={(val) => {
                      onChange(val)
                      setCensusDateStart(new Date(val).toLocaleDateString('en-CA').split('T')[0])
                    }}
                    label='Data rozpoczęcia'
                  />
                )}
              />
            </div>
            <div>
              <Controller
                name='dateEnd'
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Calendar
                    date={value}
                    handleDate={(val) => {
                      onChange(val)
                      setCensusDateEnd(new Date(val).toLocaleDateString('en-CA').split('T')[0])
                    }}
                    label='Data zakończenia'
                  />
                )}
              />
            </div>
          </div>
          <hr className='my-4' />
          <div className='mt-4 flex justify-center'>
            <Button label={submitting ? 'Czekaj...' : 'Utwórz spis'} disabled={submitting} />
          </div>
        </form>
      </Card>
      <div className='my-4 flex justify-center gap-4'>
        <Button variant='secondary' label='Powrót' onClick={() => navigate(path.census.list)} />
      </div>
    </>
  )
}

export default CensusCreate
