import { Fragment } from 'react'
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/20/solid'
import { useNavigate } from 'react-router-dom'
import DOMPurify from 'dompurify'

type NotificationType = {
  id: string
  header: string
  content: string
  readAt: string | null
  type: string
  metadata: {
    censusId?: string // Making censusId optional
    memberId?: string // Adding memberId
    migrationRequestId?: string // Adding migrationRequestId
    exportId?: string // Adding exportId
    orderId?: string // Adding exportId
    documentId?: string // Adding documentId
    changeRequestId?: string // Adding changeRequestId
  }
}

type ToastProps = {
  notification: NotificationType
  onMarkAsRead: (id: string) => void
  isOpen: boolean
  handleClose: () => void
}

const sanitizeHtml = (html: string) => {
  return {
    __html: DOMPurify.sanitize(html),
  }
}

export const NotificationModal = ({ isOpen, handleClose, notification }: ToastProps) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as='div' className='relative z-50' onClose={handleClose}>
        <TransitionChild
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-black/25' />
        </TransitionChild>

        <div className='fixed inset-0 overflow-y-auto'>
          <div className='flex min-h-full items-center justify-center p-4 text-center'>
            <TransitionChild
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <DialogPanel className='relative w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
                <DialogTitle as='h3' className='text-lg font-medium leading-6 text-gray-900'>
                  {notification.header}
                </DialogTitle>
                <button
                  type='button'
                  className='absolute right-2 top-2 text-gray-400 hover:text-gray-500'
                  onClick={handleClose}
                >
                  <span className='sr-only'>Close</span>
                  <XMarkIcon className='h-6 w-6' />
                </button>

                <div
                  className='mt-2 text-sm text-gray-500'
                  dangerouslySetInnerHTML={sanitizeHtml(notification.content)}
                />

                {notification.type === 'changerequest_new' ||
                  (notification.type === 'changerequest_handled' && (
                    <div className='mt-4'>
                      <a
                        className='inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'
                        href={`/member/notifications/${notification.metadata.changeRequestId}`}
                      >
                        Przejdź do zgłoszenia
                      </a>
                    </div>
                  ))}
                {notification.type === 'member_addedtounit' ||
                  (notification.type === 'member_removedfromunit' && (
                    <div className='mt-4'>
                      <a
                        className='inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'
                        href={`/member/${notification.metadata.memberId}`}
                      >
                        Przejdź do profilu
                      </a>
                    </div>
                  ))}
                {notification.type === 'migrationrequest_new' ||
                  notification.type === 'migrationrequest_accepted' ||
                  (notification.type === 'migrationrequest_rejected' && (
                    <div className='mt-4'>
                      <a
                        className='inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'
                        href={`/branch/migrations/${notification.metadata.migrationRequestId}`}
                      >
                        Przejdź do wniosku
                      </a>
                    </div>
                  ))}
                {notification.type === 'census_completed' ||
                  notification.type === 'census_started' ||
                  (notification.type === 'census_rejected' && (
                    <div className='mt-4'>
                      <a
                        className='inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'
                        href={`/census/${notification.metadata.censusId}/show`}
                      >
                        Przejdź do spisu
                      </a>
                    </div>
                  ))}
                {notification.type === 'export_generated' ||
                  (notification.type === 'export_tomanyresults' && (
                    <div className='mt-4'>
                      <a
                        className='inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'
                        href={`/export/${notification.metadata.exportId}`}
                      >
                        Przejdź do eksportu
                      </a>
                    </div>
                  ))}

                {notification.type === 'order_new' ||
                  (notification.type === 'order_ack' && (
                    <div className='mt-4'>
                      <a
                        className='inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'
                        href={`/orders/issued/${notification.metadata.orderId}`}
                      >
                        Przejdź do eksportu
                      </a>
                    </div>
                  ))}

                {notification.type === 'document_generated' && (
                  <div className='mt-4'>
                    <a
                      className='inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'
                      href={`/document/${notification.metadata.documentId}`}
                    >
                      Przejdź do dokumentu
                    </a>
                  </div>
                )}
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
