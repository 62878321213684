import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { Button } from 'components/Atoms/Button'
import { Calendar } from 'components/Atoms/Calendar'
import { Card } from 'components/Atoms/Card'
import { Select } from 'components/Atoms/Select'
import { TextField } from 'components/Atoms/TextField'
import { addTrainigFile, createTraining } from 'fetchers/trainingFetchers'
import React, { useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { mutationErrorHandler } from 'tools/errorHandler'
import { errorToast, successToast } from 'tools/ToastHelpers'
import { IAxiosErrors } from 'types/axios-errors'
import { path } from 'constants/path'
import { queryKeys } from 'constants/queryKeys'
import { exportTrainingErrors } from 'error-data/training'
import { MultiSelect } from 'react-multi-select-component'
import { IOption } from 'types/form'

const TrainingCreate = () => {
  const [fileIsAdding, setFileIsAdding] = useState(false)
  const [trainingName, setTrainingName] = useState('')
  const [trainerName, setTrainerName] = useState('')
  const [trainingHour, setTrainingHour] = useState('')
  const [trainigType, setTrainigType] = useState('')
  const [trainingDateStart, setTrainingDateStart] = useState('')
  const [trainingLocation, setTrainingLocation] = useState('')
  const [trainingUrl, setTrainingUrl] = useState('')
  const [locationDetails, setLocationDetails] = useState('')
  const [trainingDescription, setTrainingDescription] = useState('')

  const [file, setFile] = useState<File>()
  const [fileIds, setFileIds] = useState<string[]>([])
  const [finalFiles, setFinalFiles] = useState<IOption[]>([])
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [isSending, setIsSending] = useState(false)

  const { control } = useForm()
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: (data: any) => {
      const { redacted, status, ...formData } = data
      return createTraining(formData)
    },
    onSuccess: (data) => {
      successToast('Dodano żądanie utworzenia szkolenia.')
      queryClient.invalidateQueries({ queryKey: [queryKeys.trainingCreate] })
      const trainingId = data.id
      navigate(`/training/${trainingId}/update`)
      setIsSending(false)
    },
    onError: (error: AxiosError<IAxiosErrors>) => {
      setIsSending(false)

      // Check if the error status is 403
      if (error.response?.status === 403) {
        errorToast(
          'Brak dostępu: Proszę upewnić się, że masz odpowiednie uprawnienia do utworzenia szkolenia.',
        )
        return
      }

      const errorMessages = error.response?.data.errors
        .map((item: any) => `${item.property}: ${item.error}`)
        .join('\n')

      if (errorMessages) {
        error.response?.data.errors.forEach((item: any) => {
          toast.warning(errorMessages)
        })
      }

      mutationErrorHandler(error, exportTrainingErrors.create, errorMessages)
      setFileIsAdding(false)
      errorToast('Wystąpił błąd podczas tworzenia szkolenia.', error)
    },
  })

  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!trainingName.trim()) {
      errorToast('Nazwa pliku jest wymagana.')
      return
    }
    if (!trainerName.trim()) {
      errorToast('Imię i nazwisko prowadzącego jest wymagane.')
      return
    }
    if (!trainingDateStart.trim()) {
      errorToast('Data rozpoczęcia jest wymagana.')
      return
    }
    if (!trainingHour.trim()) {
      errorToast('Godzina rozpoczęcia jest wymagana.')
      return
    }
    if (!trainigType.trim()) {
      errorToast('Tryb szkolenia jest wymagany.')
      return
    }
    if (!trainingLocation.trim()) {
      errorToast('Miejsce szkolenia jest wymagane.')
      return
    }
    if (!trainingUrl.trim()) {
      errorToast('URL jest wymagane.')
      return
    }
    if (!locationDetails.trim()) {
      errorToast('Szczegóły lokalizacji są wymagane.')
      return
    }
    if (!trainingDescription.trim()) {
      errorToast('Opis szkolenia jest wymagany.')
      return
    }

    setIsSending(true)

    const exportData = {
      name: trainingName,
      dateStart: trainingDateStart,
      hourStart: trainingHour,
      trainerName: trainerName,
      location: trainingLocation,
      locationDetails: locationDetails,
      type: trainigType,
      url: trainingUrl,
      description: trainingDescription,
      files: fileIds,
    }

    mutation.mutate(exportData)
  }

  const filteredFiles = fileIds.map((fileId, key) => ({
    value: fileId,
    label: `Plik ${key + 1}`,
  }))

  const fileMutation = useMutation({
    mutationFn: (data: File) => {
      return addTrainigFile(data)
    },
    onSuccess: (data) => {
      const fileId = data.id
      const newFile = { value: fileId, label: `Plik ${fileIds.length + 1}` }
      setFileIds((prevFileIds) => [...prevFileIds, fileId])
      setFinalFiles((prevFinalFiles) => [...prevFinalFiles, newFile])
      if (fileInputRef.current) {
        fileInputRef.current.value = ''
      }
      successToast('Plik został dodany.')
      setFileIsAdding(false)
      setFile(undefined)
    },
    onError: (error: AxiosError<IAxiosErrors>) => {
      const errorMessages = error.response?.data.errors
        .map((item: any) => `${item.property}: ${item.error}`)
        .join('\n')
      error.response?.data.errors.forEach((item: any) => {
        toast.warning(errorMessages)
      })
      mutationErrorHandler(error, exportTrainingErrors.create, errorMessages)
      setFileIsAdding(false)
    },
  })

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files ? event.target.files[0] : undefined
    setFile(selectedFile)
  }

  const handleAddFile = (e: React.FormEvent) => {
    e.preventDefault()
    if (!file) {
      errorToast('Plik jest wymagany.')
      return
    }
    setFileIsAdding(true)
    fileMutation.mutate(file)
  }

  return (
    <Card label='Utwórz szkolenie'>
      <form onSubmit={submitHandler}>
        <div className='grid grid-cols-1 gap-4 pb-4 sm:grid-cols-2 md:grid-cols-4'>
          <TextField
            onChange={(e) => {
              setTrainingName(e.target.value)
            }}
            label='Nazwa szkolenia'
            placeholder='Wpisz nazwę szkolenia'
            name='trainingName'
            type='text'
            value={trainingName}
          />
          <div className='flex gap-2'>
            <div className='flex-1'>
              <Controller
                name='dateStart'
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Calendar
                    date={value}
                    handleDate={(val) => {
                      onChange(val)
                      setTrainingDateStart(new Date(val).toISOString().split('T')[0])
                    }}
                    label='Data rozpoczęcia'
                  />
                )}
              />
            </div>
          </div>
          <div>
            <label className='mb-1 block text-sm font-medium text-gray-700' htmlFor='training-hour'>
              Godzina szkolenia
            </label>
            <input
              type='time'
              name='training-hour'
              value={trainingHour}
              onChange={(e) => {
                setTrainingHour(e.target.value)
              }}
              className='block w-full rounded-md border-0 py-1.5 text-sm leading-6 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600'
              id='training-hour'
            />
          </div>
          <div>
            <Controller
              name='type'
              control={control}
              render={({ field: { onChange } }) => (
                <Select
                  handleSelect={({ value, label }) => {
                    setTrainigType(value.toString())
                    onChange(value)
                  }}
                  label='Tryb szkolenia'
                  withEmpty
                  selectLabel='---'
                  options={[
                    { label: 'Stacjonarne', value: 'on-site' },
                    { label: 'Online', value: 'online' },
                  ]}
                />
              )}
            />
          </div>
          <TextField
            onChange={(e) => {
              setTrainerName(e.target.value)
            }}
            label='Prowadzący'
            placeholder='Wpisz imię prowadzącego'
            name='trainerName'
            type='text'
            value={trainerName}
          />

          <div>
            <label
              className='mb-1 block text-sm font-medium text-gray-700'
              htmlFor='training-location'
            >
              Miejsce szkolenia
            </label>
            <input
              id='training-location'
              type='text'
              name='address'
              value={trainingLocation}
              onChange={(e) => {
                setTrainingLocation(e.target.value)
              }}
              className='block w-full rounded-md border-0 py-1.5 text-sm leading-6 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600'
            />
          </div>
          <div>
            <label className='mb-1 block text-sm font-medium text-gray-700' htmlFor='url'>
              URL
            </label>
            <input
              id='url'
              type='text'
              name='url'
              className='block w-full rounded-md border-0 py-1.5 text-sm leading-6 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600'
              value={trainingUrl}
              onChange={(e) => {
                setTrainingUrl(e.target.value)
              }}
            />
          </div>
          <div className='md:col-span-2'>
            <label
              className='mb-1 block text-sm font-medium text-gray-700'
              htmlFor='locationDetails'
            >
              Szczegóły lokalizacji
            </label>
            <textarea
              id='locationDetails'
              name='locationDetails'
              value={locationDetails}
              onChange={(e) => {
                setLocationDetails(e.target.value)
              }}
              aria-rowspan={4}
              className='block w-full rounded-md border-0 py-1.5 text-sm leading-6 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600'
              placeholder='Wpisz szczegóły dot. lokalizacji'
            ></textarea>
          </div>
          <div className='md:col-span-2'>
            <label
              className='mb-1 block text-sm font-medium text-gray-700'
              htmlFor='trainingDescription'
            >
              Opis szkolenia
            </label>
            <textarea
              id='trainingDescription'
              name='trainingDescription'
              value={trainingDescription}
              onChange={(e) => {
                setTrainingDescription(e.target.value)
              }}
              aria-rowspan={4}
              className='block w-full rounded-md border-0 py-1.5 text-sm leading-6 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600'
              placeholder='Opisz szkolenie'
            ></textarea>
          </div>

          <div>
            <label className='mb-1 block text-sm font-medium text-gray-700' htmlFor='docs'>
              Dokumenty
            </label>
            <MultiSelect
              options={filteredFiles}
              value={finalFiles}
              onChange={setFinalFiles}
              hasSelectAll={false}
              className='text-sm'
              overrideStrings={{
                selectSomeItems: 'Zaznacz dokumenty',
                allItemsAreSelected: 'Wszystkie dokumenty są zaznaczone',
                selectAll: 'Zaznacz wszystkie',
                search: 'Wyszukaj',
                noOptions: 'Brak dokumentów',
              }}
              labelledBy='Wyszukaj'
            />
          </div>
          <div className='md:col-span-3'></div>
          <div className='md:col-span-2'>
            <label className='mb-1 block text-sm font-medium text-gray-700'>Dodaj plik</label>
            <div className='flex items-center gap-2'>
              <input
                type='file'
                name='file'
                id='file'
                ref={fileInputRef}
                className='mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                onChange={handleFileChange}
              />
              <Button
                className='mt-1 flex min-w-[130px] justify-center text-center disabled:cursor-not-allowed'
                disabled={!file}
                label={
                  fileIsAdding ? (
                    <>
                      Dodaję plik
                      <span className='loader ml-1'></span>
                    </>
                  ) : (
                    'Dodaj plik'
                  )
                }
                onClick={handleAddFile}
              />
            </div>
          </div>
        </div>

        <hr className='my-4' />
        <div className='my-4 flex justify-center'>
          <Button
            variant='secondary'
            label='Anuluj'
            className='mr-4'
            onClick={(e) => {
              e.preventDefault()
              navigate(path.training.list)
            }}
          />
          <Button label='Utwórz szkolenie' disabled={isSending} />
        </div>
      </form>
    </Card>
  )
}

export default TrainingCreate
