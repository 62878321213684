import { useMutation, useQuery } from '@tanstack/react-query'
import { queryKeys } from 'constants/queryKeys'
import {
  getCensusSurvey,
  markCensusMemberAsConfirmed,
  markCensusMemberAsNotRemoved,
  markCensusMemberAsRemoved,
  markCensusMemberAsUnconfirmed,
  setCensusSurveyValue,
} from 'fetchers/censusFetchers'
import { useEffect, useState } from 'react'
import { CheckIcon, PlusCircleIcon, TrashIcon, XMarkIcon } from '@heroicons/react/20/solid'
import { Button } from 'components/shadcn/ui/button'
import { useForm } from 'react-hook-form'
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from 'components/shadcn/ui/card'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'components/shadcn/ui/table'
import { ToggleGroup, ToggleGroupItem } from 'components/shadcn/ui/toggle-group'
import { getMemberEmployment, getMembersDetails } from 'fetchers/membersFetchers'
import { Label } from 'components/shadcn/ui/label'
import { errorToast, successToast } from 'tools/ToastHelpers'
import { mutationErrorHandler } from 'tools/errorHandler'
import { notificationErrors } from 'error-data/notification'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogHeader,
  DialogDescription,
} from 'components/shadcn/ui/dialog'
import {
  Select as ShadCnSelect,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/shadcn/ui/select'
import { toast } from 'sonner'

export const MemberCard = ({
  censusMemberId,
  memberSection,
  userToken,
  censusId,
  refetchCensusMembersSection,
  isAddedMember = false,
  institutionList,
  positionList,
}: any) => {
  const [open, setOpen] = useState(false)
  const [memberRemoveDescription, setMemberRemoveDescription] = useState('')
  const [memberRemoveReason, setMemberRemoveReason] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [surveyData, setSurveyData] = useState<any>({})
  const [isDataChanged, setIsDataChanged] = useState(false)

  const isMemberObject = memberSection?.member ? true : false
  const member = isMemberObject ? memberSection?.member : memberSection

  const { data: censusSurveyData, isLoading: censusSurveyDataLoading } = useQuery({
    queryKey: [userToken, queryKeys.censusSurveyData, censusId],
    queryFn: () => getCensusSurvey(censusId),
    enabled: !isAddedMember,
    retry: false,
  })

  useEffect(() => {
    if (memberSection?.surveyValue) {
      setSurveyData(memberSection.surveyValue)
    } else if (censusSurveyData) {
      // Initialize empty survey data based on the structure
      const initialSurveyData = {
        groups: censusSurveyData.groups.map((group: any) => ({
          name: group.name,
          rows: [{ items: group.items.map((item: any) => ({ name: item.name, value: '' })) }],
        })),
      }
      setSurveyData(initialSurveyData)
    }
  }, [memberSection, censusSurveyData])

  const mutateSetCensusSurveyValue = useMutation({
    mutationFn: async (data: any) => setCensusSurveyValue(censusId, censusMemberId, data),
    onSuccess: () => {
      successToast('Dane ankiety zostały zapisane.')
      refetchCensusMembersSection()
      setIsDataChanged(false)
    },
    onError: (error: any) => {
      mutationErrorHandler(
        error,
        notificationErrors.update,
        'Nie udało się zapisać danych ankiety.',
      )
    },
  })

  const handleSurveyChange = (
    groupIndex: number,
    rowIndex: number,
    field: string,
    value: string,
  ) => {
    const updatedSurveyData = { ...surveyData }
    const group = updatedSurveyData.groups[groupIndex]
    if (!group.rows[rowIndex]) {
      group.rows[rowIndex] = { items: [] }
    }
    const itemIndex = group.rows[rowIndex].items.findIndex((item: any) => item.name === field)
    if (itemIndex > -1) {
      group.rows[rowIndex].items[itemIndex].value = value
    } else {
      group.rows[rowIndex].items.push({ name: field, value })
    }
    setSurveyData(updatedSurveyData)
    setIsDataChanged(true)
  }

  const addSurveyRow = (groupIndex: number) => {
    const updatedSurveyData = { ...surveyData }
    const group = updatedSurveyData.groups[groupIndex]
    const newRow = {
      items: censusSurveyData.groups[groupIndex].items.map((item: any) => ({
        name: item.name,
        value: '',
      })),
    }
    group.rows.push(newRow)
    setSurveyData(updatedSurveyData)
    mutateSetCensusSurveyValue.mutate(updatedSurveyData)
  }

  const handleSaveSurvey = () => {
    if (isDataChanged) {
      mutateSetCensusSurveyValue.mutate(surveyData)
    } else {
      errorToast('Brak zmian do zapisania.')
    }
  }

  const mutateMarkAsConfirmed = useMutation({
    mutationFn: async ({ censusId, memberID }: { censusId: string; memberID: string }) =>
      markCensusMemberAsConfirmed(censusId, memberID),
    onSuccess: () => {
      successToast('Potwierdzono członka.')
      refetchCensusMembersSection()
      setIsLoading(false)
    },
    onError: (error: any) => {
      setIsLoading(false)
      mutationErrorHandler(error, notificationErrors.remove, 'Nie udało się potwierdzić członka.')
    },
  })

  const mutateMarkAsUnconfirmed = useMutation({
    mutationFn: async ({ censusId, memberID }: { censusId: string; memberID: string }) =>
      markCensusMemberAsUnconfirmed(censusId, memberID),
    onSuccess: () => {
      successToast('Członek został oznaczony jako niepotwierdzony.')
      refetchCensusMembersSection()
      setIsLoading(false)
    },
    onError: (error: any) => {
      setIsLoading(false)
      mutationErrorHandler(
        error,
        notificationErrors.update,
        'Nie udało się oznaczyć członka jako niepotwierdzonego.',
      )
    },
  })

  const mutateMarkAsRemoved = useMutation({
    mutationFn: async ({
      censusId,
      memberID,
      data,
    }: {
      censusId: string
      memberID: string
      data: { reason: string; description: string }
    }) => markCensusMemberAsRemoved(censusId, memberID, data),
    onSuccess: () => {
      successToast('Członek został oznaczony jako usunięty.')
      refetchCensusMembersSection()
      setIsLoading(false)
      setOpen(false)
    },
    onError: (error: any) => {
      setIsLoading(false)
      mutationErrorHandler(
        error,
        notificationErrors.remove,
        'Nie udało się oznaczyć członka jako usuniętego.',
      )
    },
  })

  const mutateMarkAsNotRemoved = useMutation({
    mutationFn: async ({ censusId, memberID }: { censusId: string; memberID: string }) =>
      markCensusMemberAsNotRemoved(censusId, memberID),
    onSuccess: () => {
      setIsLoading(false)
      successToast('Członek został przywrócony.')
      refetchCensusMembersSection()
    },
    onError: (error: any) => {
      setIsLoading(false)
      mutationErrorHandler(error, notificationErrors.update, 'Nie udało się przywrócić członka.')
    },
  })

  const handleMarkAsConfirmed = async () => {
    try {
      if (confirm('Czy na pewno chcesz potwierdzić członka?')) {
        mutateMarkAsConfirmed.mutate({ censusId, memberID: censusMemberId })
      }
    } catch (error) {
      console.error(error)
      mutationErrorHandler(null, null, 'Nie udało się potwierdzić członka.')
    }
  }

  const openRemoveModal = () => {
    if (memberSection.isRemoved) {
      if (confirm('Czy chcesz przywrócić członka?')) {
        mutateMarkAsNotRemoved.mutate({ censusId, memberID: censusMemberId })
      }
    } else {
      setOpen(true)
    }
  }

  const submitHandler = (e: React.FormEvent) => {
    setIsLoading(true)
    e.preventDefault()

    if (!memberRemoveReason.trim()) {
      errorToast('Powód usunięcia jest wymagany.')
      setIsLoading(false)
      return
    }
    if (!memberRemoveDescription.trim()) {
      errorToast('Komentarz jest wymagany. Minimum 5 znaków.')
      setIsLoading(false)
      return
    }

    const exportData = {
      description: memberRemoveDescription,
      reason: memberRemoveReason,
    }

    mutateMarkAsRemoved.mutate({ censusId, memberID: censusMemberId, data: exportData })
  }

  const handleConfirmedMember = () => {
    if (memberSection.isConfirmed) {
      if (confirm('Czy chcesz oznaczyć członka jako niepotwierdzonego?')) {
        mutateMarkAsUnconfirmed.mutate({ censusId, memberID: censusMemberId })
      }
    } else {
      handleMarkAsConfirmed()
    }
  }

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle className='text-lg'>{`${member?.firstName} ${member?.lastName}`}</CardTitle>
        </CardHeader>
        <CardContent>
          <div className='flex flex-col gap-4'>
            {censusSurveyData?.groups.map((group: any, groupIndex: number) => (
              <div key={group.name} className='flex-1'>
                <h3 className='text-md mb-2 border-b border-slate-300 pb-4 font-semibold'>
                  {group.label}
                </h3>
                <Table>
                  <TableHeader>
                    <TableRow>
                      {group.items.map((item: any) => (
                        <TableHead key={item.name} className='min-w-[180px] max-w-[250px]'>
                          {item.label}
                        </TableHead>
                      ))}
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {surveyData.groups?.[groupIndex]?.rows.map((row: any, rowIndex: number) => (
                      <TableRow key={rowIndex}>
                        {group.items.map((item: any) => (
                          <TableCell key={item.name}>
                            <ShadCnSelect
                              value={row.items.find((i: any) => i.name === item.name)?.value || ''}
                              onValueChange={(value) =>
                                handleSurveyChange(groupIndex, rowIndex, item.name, value)
                              }
                            >
                              <SelectTrigger className='w-full max-w-[300px]'>
                                <SelectValue placeholder={`Wybierz ${item.label.toLowerCase()}`} />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectGroup className='max-w-sm'>
                                  {(item.type === 'institution'
                                    ? institutionList
                                    : positionList
                                  )?.map((option: any) => (
                                    <SelectItem key={option.value} value={option.value}>
                                      {option.label}
                                    </SelectItem>
                                  ))}
                                </SelectGroup>
                              </SelectContent>
                            </ShadCnSelect>
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                {group.config.repeatable && (
                  <CardFooter className='border-t px-0 py-4'>
                    <Button
                      size='sm'
                      variant='ghost'
                      className='gap-1'
                      onClick={() => addSurveyRow(groupIndex)}
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='16'
                        height='16'
                        fill='currentColor'
                        className='h-4 w-4'
                        viewBox='0 0 16 16'
                      >
                        <path
                          fillRule='evenodd'
                          d='M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2'
                        />
                      </svg>
                      Dodaj {group.label.toLowerCase()}
                    </Button>
                  </CardFooter>
                )}
                <CardFooter className='px-0'>
                  <Button
                    onClick={handleSaveSurvey}
                    disabled={!isDataChanged || mutateSetCensusSurveyValue.isPending}
                  >
                    {mutateSetCensusSurveyValue.isPending
                      ? 'Zapisywanie...'
                      : 'Zapisz i wyślij ankietę'}
                  </Button>
                </CardFooter>
              </div>
            ))}
            {!isAddedMember && (
              <div className='min-w-[150px]'>
                <h3 className='text-md mb-2 border-b border-slate-300 pb-4 font-semibold'>
                  Potwierdź lub usuń członka
                </h3>
                <ToggleGroup className='justify-start p-2' type='single' variant='outline'>
                  <ToggleGroupItem
                    key={memberSection.isConfirmed ? 'confirmed' : 'not-confirmed'}
                    value='s'
                    className={`${memberSection.isConfirmed ? 'bg-green-300' : ''}`}
                    onClick={handleConfirmedMember}
                  >
                    <CheckIcon className='h-4 w-4' />
                  </ToggleGroupItem>
                  <ToggleGroupItem
                    key={memberSection.isRemoved ? 'removed' : 'not-removed'}
                    value='m'
                    className={`${memberSection.isRemoved ? 'bg-red-300' : ''}`}
                    onClick={openRemoveModal}
                  >
                    <TrashIcon className='h-4 w-4' />
                  </ToggleGroupItem>
                </ToggleGroup>
              </div>
            )}
          </div>
        </CardContent>
      </Card>

      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle className='pb-4'>Usuń członka</DialogTitle>
            <DialogDescription></DialogDescription>
            <form onSubmit={submitHandler}>
              <div className='space-y-4'>
                <div>
                  <fieldset className='md:col-span-2'>
                    <legend className='mb-1 block text-sm font-medium text-gray-700'>
                      Powód usunięcia członka
                    </legend>
                    <div className='space-y-2'>
                      <div className='flex items-center gap-x-3'>
                        <input
                          id='died'
                          name='reason'
                          type='radio'
                          className='h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600'
                          value='died'
                          onChange={(e) => setMemberRemoveReason(e.target.value)}
                        />
                        <label
                          htmlFor='died'
                          className='block text-sm font-medium leading-6 text-gray-900'
                        >
                          Zgon
                        </label>
                      </div>

                      <div className='flex items-center gap-x-3'>
                        <input
                          id='institution_changed'
                          name='reason'
                          type='radio'
                          className='h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600'
                          value='institution_changed'
                          onChange={(e) => setMemberRemoveReason(e.target.value)}
                        />
                        <label
                          htmlFor='institution_changed'
                          className='block text-sm font-medium leading-6 text-gray-900'
                        >
                          Zmiana placówki
                        </label>
                      </div>
                      <div className='flex items-center gap-x-3'>
                        <input
                          id='retired'
                          name='reason'
                          type='radio'
                          className='h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600'
                          value='retired'
                          onChange={(e) => setMemberRemoveReason(e.target.value)}
                        />
                        <label
                          htmlFor='retired'
                          className='block text-sm font-medium leading-6 text-gray-900'
                        >
                          Emerytura
                        </label>
                      </div>

                      <div className='flex items-center gap-x-3'>
                        <input
                          id='union_left'
                          name='reason'
                          type='radio'
                          className='h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600'
                          value='union_left'
                          onChange={(e) => setMemberRemoveReason(e.target.value)}
                        />
                        <label
                          htmlFor='union_left'
                          className='block text-sm font-medium leading-6 text-gray-900'
                        >
                          Odejście związku
                        </label>
                      </div>

                      <div className='flex items-center gap-x-3'>
                        <input
                          id='profession_left'
                          name='reason'
                          type='radio'
                          className='h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600'
                          value='profession_left'
                          onChange={(e) => setMemberRemoveReason(e.target.value)}
                        />
                        <label
                          htmlFor='profession_left'
                          className='block text-sm font-medium leading-6 text-gray-900'
                        >
                          Zmiana zawodu
                        </label>
                      </div>

                      <div className='flex items-center gap-x-3'>
                        <input
                          id='other'
                          name='reason'
                          type='radio'
                          className='h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600'
                          value='other'
                          onChange={(e) => setMemberRemoveReason(e.target.value)}
                        />
                        <label
                          htmlFor='other'
                          className='block text-sm font-medium leading-6 text-gray-900'
                        >
                          Inny
                        </label>
                      </div>
                    </div>
                  </fieldset>
                </div>
                <div className='md:col-span-4'>
                  <label
                    className='mb-1 block text-sm font-medium text-gray-700'
                    htmlFor='memberRemoveDescription'
                  >
                    Komentarz do usunięcia
                  </label>
                  <textarea
                    id='memberRemoveDescription'
                    name='memberRemoveDescription'
                    value={memberRemoveDescription}
                    onChange={(e) => {
                      setMemberRemoveDescription(e.target.value)
                    }}
                    aria-rowspan={4}
                    className='block w-full rounded-md border-0 py-1.5 text-sm leading-6 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600'
                    placeholder='Opisz powód usunięcia członka'
                    minLength={5}
                  ></textarea>
                </div>
                <Button variant='destructive' disabled={isLoading}>
                  {isLoading && <div className='loader'></div>}
                  Usuń członka
                </Button>
              </div>
            </form>
          </DialogHeader>
        </DialogContent>
      </Dialog>
    </>
  )
}
